import React from 'react';

import './header1.sass';


const Header1: React.FC = () => {
    return (
        <div className='header1' id='up1'>
            <ul className="header1__nav">
                <li className="header1__nav_item"><a href="#">О нас</a></li>
                <li className="header1__nav_item"><a href="#">Оплата и доставка</a></li>
                <li className="header1__nav_item"><a href="#">Товары из Китая</a></li>
                <li className="header1__nav_item"><a href="#">Статьи</a></li>
            </ul>
            <div className="header1__social">
                <a href="#" className="header1__social_icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M16.8 7.56135L14.9968 16.9754C14.9968 16.9754 14.7445 17.6281 14.0514 17.315L9.89077 14.0116L9.87148 14.0018C10.4335 13.4792 14.7915 9.42161 14.9819 9.23768C15.2768 8.95283 15.0937 8.78325 14.7514 8.99843L8.3141 13.2318L5.83061 12.3665C5.83061 12.3665 5.43978 12.2225 5.40218 11.9095C5.36409 11.5959 5.84347 11.4264 5.84347 11.4264L15.9679 7.31338C15.9679 7.31338 16.8 6.93477 16.8 7.56135Z" fill="#282828"/>
                    </svg>
                </a>
                <a href="#" className="header1__social_icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M16.7561 7.23752C15.5707 6.05627 13.9902 5.40002 12.3219 5.40002C8.85366 5.40002 6.0439 8.20002 6.0439 11.6563C6.0439 12.75 6.35122 13.8438 6.87805 14.7625L6 18L9.33659 17.125C10.2585 17.6063 11.2683 17.8688 12.3219 17.8688C15.7902 17.8688 18.6 15.0688 18.6 11.6125C18.5561 9.99377 17.9415 8.41877 16.7561 7.23752ZM15.3512 13.8875C15.2195 14.2375 14.6049 14.5875 14.2976 14.6313C14.0341 14.675 13.6829 14.675 13.3317 14.5875C13.1122 14.5 12.8049 14.4125 12.4537 14.2375C10.8732 13.5813 9.86342 12.0063 9.77561 11.875C9.68781 11.7875 9.11707 11.0438 9.11707 10.2563C9.11707 9.46877 9.51219 9.11877 9.6439 8.94377C9.77561 8.76877 9.95122 8.76877 10.0829 8.76877C10.1707 8.76877 10.3024 8.76877 10.3902 8.76877C10.478 8.76877 10.6098 8.72502 10.7415 9.03127C10.8732 9.33752 11.1805 10.125 11.2244 10.1688C11.2683 10.2563 11.2683 10.3438 11.2244 10.4313C11.1805 10.5188 11.1366 10.6063 11.0488 10.6938C10.961 10.7813 10.8732 10.9125 10.8293 10.9563C10.7415 11.0438 10.6537 11.1313 10.7415 11.2625C10.8293 11.4375 11.1366 11.9188 11.6195 12.3563C12.2341 12.8813 12.7171 13.0563 12.8927 13.1438C13.0683 13.2313 13.1561 13.1875 13.2439 13.1C13.3317 13.0125 13.639 12.6625 13.7268 12.4875C13.8146 12.3125 13.9463 12.3563 14.078 12.4C14.2098 12.4438 15 12.8375 15.1317 12.925C15.3073 13.0125 15.3951 13.0563 15.439 13.1C15.4829 13.2313 15.4829 13.5375 15.3512 13.8875Z" fill="#282828"/>
                    </svg>
                </a>
            </div>
        </div>
    );
};

export default Header1;